import React from 'react';
import AccountInput from "../components/form/AccountInput";
import MobileCodeInput from "../components/form/MobileCodeInput";
import LoginButton from "../components/form/LoginButton";
import LoginRedirect from "../components/form/LoginRedirect";
import "./RetrievePasswordStyle.less";
import PasswordInput from "../components/form/PasswordInput";
import Utils from "../utils/Utils";
import {Alert, Modal} from "antd";
import AlertPlaceholder from "../components/form/AlertPlaceholder";

class RetrievePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			captcha: {
				instance: null,
				status: "init",
				token: null,
			},
			message: {
				type: "",
				message: ""
			},
			account: {
				value: "",
				error: ""
			},
			code: {
				value: "",
				error: "",
				text: "",
				countdown: -1
			},
			password: {
				value: "",
				error: ""
			},
			repeatPassword: {
				value: "",
				error: ""
			},
			loginButton: {
				loading: false
			}
		};
	}

	changeLoginType() {
		this.props.changeLoadComponent("accountLogin");
	}

	componentDidMount() {
		if (this.state.captcha.instance) {
			const that = this;
			const captchaState = this.state.captcha;
			captchaState.instance = this.props.loginConfig.captcha.new({
				groupCode: this.props.loginConfig.group_code,
				vid: this.props.loginConfig.vaptcha_id,
				onSuccess: function (token) {
					that.sendMobileCode(token);
				}
			});
		}
	}

	handleAccountOnBlur(event) {
		const loginAccount = this.state.account;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginAccount.error = "请输入手机号码";
			this.setState({
				account: loginAccount
			});
		} else {
			if (!Utils.isPhoneAvailable(this.state.account.value)) {
				this.setState({
					account: {
						value: this.state.account.value,
						error: "登录手机号码格式错误"
					}
				})
			}
		}
	}

	handleAccountOnChange(event) {
		const loginAccount = this.state.account;
		let inputValue = event.target.value;
		loginAccount.value = inputValue;
		if (!inputValue) {
			loginAccount.error = "请输入手机号码";
		} else {
			loginAccount.error = "";
		}
		this.setState({
			account: loginAccount
		})
	}

	codeTimeCountDown() {
		let codeState = this.state.code;
		if (codeState.countdown === -1) {
			codeState.countdown = 60;
		} else {
			codeState.countdown--;
		}
		this.setState({
			code: codeState
		});
		if (codeState.countdown !== -1) {
			const that = this;
			setTimeout(function () {
				that.codeTimeCountDown();
			}, 1000);
		}
	}

	handleCodeButtonOnClick() {
		if (this.state.code.countdown !== -1) {
			Modal.warning({
				content: "操作太频繁，请稍后再试！",
				okText: "确定"
			})
			return;
		}
		if (!this.state.account.value) {
			this.setState({
				account: {
					value: this.state.account.value,
					error: "请输入手机号码"
				}
			})
			return;
		}
		if (!Utils.isPhoneAvailable(this.state.account.value)) {
			this.setState({
				account: {
					value: this.state.account.value,
					error: "登录手机号码格式错误"
				}
			})
			return;
		}
		if (this.state.captcha.instance) {
			this.state.captcha.instance.verify();
		} else {
			this.sendMobileCode();
		}
	}

	handleCodeOnBlur(event) {
		const loginCode = this.state.code;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginCode.error = "请输入短信验证码";
			this.setState({
				code: loginCode
			});
		}
	}

	handleCodeOnChange(event) {
		const loginCode = this.state.code;
		let inputValue = event.target.value;
		loginCode.value = inputValue;
		if (!inputValue) {
			loginCode.error = "请输入短信验证码";
		} else {
			loginCode.error = "";
		}
		this.setState({
			code: loginCode
		})
	}

	sendMobileCode(token) {
		const that = this;
		const codeState = this.state.code;
		Utils.sendMobileCodeMessage({
			user_mobile: that.state.account.value,
			use_type: "cmc_reset_pwd",
			captcha_drive: that.props.loginConfig.captcha_drive,
			validate: token
		}, function (result) {
			if (result.data.code === 10000) {
				codeState.text = "短信验证码已发送成功";
				codeState.countdown = -1;
				codeState.error = "";
				that.setState({
					code: codeState
				})
				that.codeTimeCountDown();
			} else {
				codeState.error = result.data.message ? result.data.message : "短信验证码发送失败，请稍后重试";
				codeState.countdown = -1;
				if (that.state.captcha.instance) {
					that.state.captcha.instance.reset();
				}
				that.setState({
					code: codeState
				})
			}
		})
	}

	handlePasswordOnBlur(event) {
		const loginPassword = this.state.password;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginPassword.error = "请设置登录密码";
			this.setState({
				password: loginPassword
			});
		} else {
			if (!Utils.isPasswordAvailable(inputValue)) {
				loginPassword.error = "8-20个字符，包含字母和数字";
				this.setState({
					password: loginPassword
				});
			}
		}
	}

	handlePasswordOnChange(event) {
		const loginPassword = this.state.password;
		let inputValue = event.target.value;
		loginPassword.value = inputValue;
		if (!inputValue) {
			loginPassword.error = "请设置登录密码";
		} else {
			loginPassword.error = "";
		}
		this.setState({
			password: loginPassword
		})
	}

	handleRepeatPasswordOnBlur(event) {
		const password = this.state.password;
		const loginPassword = this.state.repeatPassword;
		if (password.value === "") {
			password.error = "请设置登录密码";
			loginPassword.error = "请先设置登录密码";
			this.setState({
				password: password,
				repeatPassword: loginPassword
			});
			return;
		}
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginPassword.error = "请重复登录密码";
			this.setState({
				repeatPassword: loginPassword
			});
		} else {
			if (this.state.password.value !== inputValue) {
				loginPassword.error = "重复密码不匹配";
				this.setState({
					repeatPassword: loginPassword
				});
			}
		}
	}

	handleRepeatPasswordOnChange(event) {
		const loginPassword = this.state.repeatPassword;
		let inputValue = event.target.value;
		loginPassword.value = inputValue;
		if (!inputValue) {
			loginPassword.error = "请重复登录密码";
		} else {
			loginPassword.error = "";
		}
		this.setState({
			repeatPassword: loginPassword
		})
	}

	submitForm() {
		if (!this.state.account.value) {
			this.setState({
				account: {
					value: this.state.account.value,
					error: "请输入手机号码"
				}
			})
			return;
		}
		if (!Utils.isPhoneAvailable(this.state.account.value)) {
			this.setState({
				account: {
					value: this.state.account.value,
					error: "手机号码格式错误"
				}
			})
			return;
		}
		const codeState = this.state.code;
		if (!codeState.value) {
			codeState.error = "请输入短信验证码";
			this.setState({
				code: codeState
			})
			return;
		}
		if (!this.state.password.value) {
			this.setState({
				password: {
					value: this.state.password.value,
					error: "请输入登录密码"
				}
			})
			return;
		}
		if (!Utils.isPasswordAvailable(this.state.password.value)) {
			this.setState({
				password: {
					value: this.state.password.value,
					error: "密码不符合当前设定强度"
				}
			})
			return;
		}
		if (this.state.password.value !== this.state.repeatPassword.value) {
			this.setState({
				repeatPassword: {
					value: this.state.repeatPassword.value,
					error: "重复密码不匹配"
				}
			})
			return;
		}
		this.resetPassword();
	}

	resetPassword() {
		this.setState({
			loginButton: {
				loading: true
			}
		});
		const data = {
			user_mobile: this.state.account.value,
			new_pwd: this.state.password.value,
			mobile_code: this.state.code.value
		};
		const that = this;
		Utils.postResetForm(data, function (result) {
			console.log(result);
			if (result.data.code === 10000) {
				that.setState({
					loginButton: {
						loading: false
					},
					message: {
						type: "success",
						message: "重置密码成功，请登录"
					}
				});
				setTimeout(function () {
					that.changeLoginType();
				}, 1000);
			} else {
				if (that.state.captcha.instance) {
					that.state.captcha.instance.reset();
				}
				that.setState({
					loginButton: {
						loading: false
					},
					message: {
						type: "error",
						message: result.data.message ? result.data.message : "重置密码失败，请稍后重试"
					},
					code: {
						value: "",
						error: ""
					}
				});
			}
		});
	}

	closeAlert = () => {
		this.setState({
			message: {
				message: ''
			}
		})
	}

	render() {
		let errorBox = null;
		if (this.state.message.message) {
			errorBox =
				<Alert
					message={this.state.message.message}
					description={this.state.message.description}
					type={this.state.message.type}
					showIcon
					banner
					closable
					afterClose={this.closeAlert}/>
		} else {
			errorBox = <AlertPlaceholder/>
		}
		return (
			<div className="retrieve-password-box login-form">
				<div className="login-type">
					<button className="active">密码找回</button>
				</div>
				<div className="form-box">
					{errorBox}
					<AccountInput placeholder="请输入手机号码" value={this.state.account.value}
								  error={this.state.account.error}
								  onChange={this.handleAccountOnChange.bind(this)}
								  onBlur={this.handleAccountOnBlur.bind(this)}></AccountInput>
					<MobileCodeInput value={this.state.code.value}
									 error={this.state.code.error}
									 message={this.state.code.text}
									 countdown={this.state.code.countdown}
									 onChange={this.handleCodeOnChange.bind(this)}
									 onBlur={this.handleCodeOnBlur.bind(this)}
									 sendCode={this.handleCodeButtonOnClick.bind(this)}></MobileCodeInput>
					<PasswordInput type="password"
								   name="login-password"
								   placeholder="设置登录密码"
								   value={this.state.password.value}
								   error={this.state.password.error}
								   onChange={this.handlePasswordOnChange.bind(this)}
								   onBlur={this.handlePasswordOnBlur.bind(this)}
					></PasswordInput>
					<PasswordInput type="password"
								   name="repeat-password"
								   placeholder="重复登录密码"
								   value={this.state.repeatPassword.value}
								   error={this.state.repeatPassword.error}
								   onChange={this.handleRepeatPasswordOnChange.bind(this)}
								   onBlur={this.handleRepeatPasswordOnBlur.bind(this)}
					></PasswordInput>
					<LoginButton name="submit" text="确认修改" loading={this.state.loginButton.loading} submitForm={this.submitForm.bind(this)}></LoginButton>
					<LoginRedirect changeLoadComponent={this.props.changeLoadComponent} resetToLogin></LoginRedirect>
				</div>
			</div>
		);
	}
}

export default RetrievePassword;