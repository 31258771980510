import React from 'react';
import {Spin} from 'antd';
import './Loading.less';

class Loading extends React.Component {
	render() {
		return (
			<div className="loading-box">
				<Spin className="loading-content"/>
			</div>
		);
	}
}

export default Loading;