import React from 'react';
import './Brand.less'
class Brand extends React.Component {
	render() {
		return (
			<div className="brand-box">
				<a href={this.props.href} alt={this.props.href}><img className="logo" src={this.props.src} alt={this.props.alt}/></a>
			</div>
		);
	}
}

export default Brand;