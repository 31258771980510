import React from "react";
import AccountInput from "../components/form/AccountInput";
import PasswordInput from "../components/form/PasswordInput";
import LoginDuration from "../components/form/LoginDuration";
import LoginRedirect from "../components/form/LoginRedirect";
import Utils from "../utils/Utils";
import {Alert, Modal} from "antd";
import LoginButton from "../components/form/LoginButton";
import ForceLogin from "./ForceLogin";
import "./AccountLoginStyle.less";
import ValidateMobile from "./ValidateMobile";
import SetMobile from "./SetMobile";
import AlertPlaceholder from "../components/form/AlertPlaceholder";

class AccountLogin extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			message: {},
			loginType: "",
			loginAccount: {
				value: "",
				error: ""
			},
			loginPassword: {
				value: "",
				error: ""
			},
			loginDuration: false,
			captcha: {
				instance: null,
				status: "init",
				token: null,
			},
			loginButton: {
				loading: false
			},
			modal: {
				name: "",
				mobile: "",
				token: ""
			}
		};
	}

	componentDidMount() {
		if (this.props.loginConfig.captcha) {
			const that = this;
			const captchaState = this.state.captcha;
			captchaState.instance = this.props.loginConfig.captcha.new({
				groupCode: this.props.loginConfig.group_code,
				vid: this.props.loginConfig.vaptcha_id,
				onSuccess: function (token) {
					that.login(token);
				}
			});
			this.setState({
				captcha: captchaState
			})
		}
	}

	changeLoginType(event, type) {
		this.props.changeLoadComponent(type ? type : "mobileLogin");
	}

	handlePasswordOnBlur(event) {
		const loginPassword = this.state.loginPassword;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginPassword.error = "请输入登录密码";
			this.setState({
				loginPassword: loginPassword
			});
		}
	}

	handlePasswordOnChange(event) {
		const loginPassword = this.state.loginPassword;
		let inputValue = event.target.value;
		loginPassword.value = inputValue;
		if (!inputValue) {
			loginPassword.error = "请输入登录密码";
		} else {
			loginPassword.error = "";
		}
		this.setState({
			loginPassword: loginPassword
		})
	}

	handleAccountOnBlur(event) {
		const loginAccount = this.state.loginAccount;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginAccount.error = "请输入登录手机号码";
			this.setState({
				loginAccount: loginAccount
			});
		}
	}

	handleAccountOnChange(event) {
		const loginAccount = this.state.loginAccount;
		let inputValue = event.target.value;
		loginAccount.value = inputValue;
		if (!inputValue) {
			loginAccount.error = "请输入登录手机号码";
		} else {
			loginAccount.error = "";
		}
		this.setState({
			loginAccount: loginAccount
		})
	}

	handleDurationOnChange(event) {
		const loginDuration = this.state.loginDuration;
		this.setState({
			loginDuration: loginDuration ? false : true
		})
	}

	submitForm(event) {
		event.stopPropagation();
		event.preventDefault();
		const loginAccount = this.state.loginAccount;
		if (loginAccount.value === "") {
			loginAccount.error = "请输入登录手机号码";
			this.setState({
				loginAccount: loginAccount
			})
			return;
		}
		const loginPassword = this.state.loginPassword;
		if (loginPassword.value === "") {
			loginPassword.error = "请输入登录密码";
			this.setState({
				loginPassword: loginPassword
			})
			return;
		}
		if (this.state.captcha.instance) {
			this.state.captcha.instance.verify();
		} else {
			this.login("");
		}
	}

	login(token) {
		this.setState({
			loginButton: {
				loading: true
			}
		});
		let loginType = "MUPwd";
		let loginModel = 1;
		if (this.props.loginConfig.group_code) {
			loginModel = 2;
			loginType = "GcLnPwd";
		}
		let encryptLoginPassword = Utils.jsEncrypt(this.state.loginPassword.value);
		const loginData = {
			group_code: this.props.loginConfig.group_code,
			login_name: this.state.loginAccount.value,
			login_type: loginType,
			login_mode: loginModel,
			ras_pwd: encryptLoginPassword
		};
		if (this.state.loginDuration) {
			loginData["login_timeout"] = window.configs.login_duration;
		}
		loginData["captcha_drive"] = this.props.loginConfig.captcha_drive;
		loginData["validate"] = token;
		let loginUrl = window.configs.local_login_url;
		if (this.props.loginConfig.login_from === "oauth-login") {
			loginData["service_key"] = this.props.loginConfig.service_key;
			loginData["successback"] = this.props.loginConfig.successback;
			loginUrl = window.configs.oauth_login_url;
		}
		const that = this;
		Utils.postLoginForm(loginData, loginUrl, function (result) {
			console.log(result.data);
			switch (result.data.code) {
				case 10000:
				case 20000:
					window.location.replace(result.data.data.successback)
					// window.location.href = result.data.data.successback;
					break;
				case 99508:
					if (that.state.captcha.instance) {
						that.state.captcha.instance.reset();
					}
					that.setState({
						loginButton: {
							loading: false
						},
						message: {
							type: "error",
							message: result.data.message ? result.data.message : "登录失败，请稍后重试"
						}
					});
					break;
				case 99807:
					//已有其他设备登录，使用手机验证码强制登录
					Modal.confirm({
						title: "当前用户已在线，是否短信验证强制登录？",
						onOk() {
							console.log(result.data);
							that.setState({
								modal: {
									name: "ForceLogin",
									mobile: result.data.data.user_mobile,
									token: result.data.data.user_token,
									groupCode: result.data.data.group_code,
									loginUrl: loginUrl
								}
							});
						},
						okText: "确定",
						onCancel() {
							that.setState({
								loginButton: {
									loading: false
								}
							});
						},
						cancelText: "取消"
					});
					break;
				case 99814:
					//需要验证手机验证码
					that.setState({
						modal: {
							name: "ValidateMobile",
							loginName: loginData.login_name,
							loginPwd: that.state.loginPassword.value,
							mobile: result.data.data.user_mobile,
							groupCode: result.data.data.group_code,
							loginUrl: loginUrl
						}
					});
					break;
				case 99815:
					//需要修改密码
					Modal.confirm({
						title: "您的密码过于简单，不符合当前系统密码强度设定，请重置密码。",
						onOk() {
							that.changeLoginType("ResetPassword");
						},
						okText: "确定",
						onCancel() {
							that.changeLoginType("ResetPassword");
						},
						cancelText: "取消"
					});
					break;
				case 99605:
					//需要设置手机号码
					Modal.confirm({
						title: "您没有设置手机号码，请完善信息。",
						onOk() {
							console.log(result.data);
							that.setState({
								modal: {
									name: "SetMobile",
									token: result.data.data.user_token,
									loginUrl: loginUrl
								}
							});
						},
						okText: "确定",
						onCancel() {
							that.setState({
								loginButton: {
									loading: false
								}
							});
						},
						cancelText: "取消"
					});
					break;
				default:
					if (that.state.captcha.instance) {
						that.state.captcha.instance.reset();
					}
					that.setState({
						loginButton: {
							loading: false
						},
						message: {
							type: "error",
							message: result.data.message ? result.data.message : "登录失败，请稍后重试"
						}
					});
			}
		});
	}

	modalHandleOk() {
	}

	modalHandleCancel() {
		this.setState({
			loginButton: {
				loading: false
			},
			modal: {
				name: "",
				mobile: "",
				token: ""
			}
		});
	}

	closeAlert = () => {
		this.setState({
			message: {
				message: ''
			}
		})
	}

	render() {
		let errorBox = null;
		if (this.state.message.message) {
			errorBox =
				<Alert
					message={this.state.message.message}
					description={this.state.message.description}
					type={this.state.message.type}
					showIcon
					banner
					closable
					afterClose={this.closeAlert}/>
		} else {
			errorBox = <AlertPlaceholder/>
		}
		let modalAlert = "";
		switch (this.state.modal.name) {
			case "ForceLogin":
				modalAlert =
					<ForceLogin {...this.state.modal}
								onOk={this.modalHandleOk.bind(this)}
								onCancel={this.modalHandleCancel.bind(this)}/>
				break;
			case "ValidateMobile":
				modalAlert =
					<ValidateMobile {...this.state.modal}
									onOk={this.modalHandleOk.bind(this)}
									onCancel={this.modalHandleCancel.bind(this)}/>
				break;
			case "SetMobile":
				modalAlert =
					<SetMobile {...this.state.modal}
							   onOk={this.modalHandleOk.bind(this)}
							   onCancel={this.modalHandleCancel.bind(this)}/>
				break;
			default:
			/**
			 * @todo nothing
			 */
		}
		return (
			<div className="login-form account-login-form">
				<div className="top-type">
					<img src={process.env.PUBLIC_URL + "/qrcode.png"} alt="qrcode"/>
				</div>
				<div className="login-type">
					<button className="active">密码登录</button>
					<button onClick={this.changeLoginType.bind(this)}>验证码登录</button>
				</div>
				<div className="account-login-box">
					{errorBox}
					{modalAlert}
					<form onSubmit={this.submitForm.bind(this)}>
						<AccountInput
							placeholder="请输入登录手机号码"
							value={this.state.loginAccount.value}
							error={this.state.loginAccount.error}
							autoCompelete={true}
							onChange={this.handleAccountOnChange.bind(this)}
							onBlur={this.handleAccountOnBlur.bind(this)}/>
						<PasswordInput
							placeholder="请输入登录密码"
							value={this.state.loginPassword.value}
							error={this.state.loginPassword.error}
							autoCompelete={true}
							onChange={this.handlePasswordOnChange.bind(this)}
							onBlur={this.handlePasswordOnBlur.bind(this)}/>
						<LoginDuration
							onChange={this.handleDurationOnChange.bind(this)}
							checked={this.state.loginDuration}/>
						<LoginButton
							name="submit"
							htmlType="submit"
							text="登录"
							loading={this.state.loginButton.loading}
						/>
						<LoginRedirect
							resetPassword={this.props.loginConfig.reset_password === "1"}
							register={this.props.loginConfig.open_register === "1"}
							changeLoadComponent={this.props.changeLoadComponent}/>
					</form>
				</div>
			</div>
		);
	}
}

export default AccountLogin;