import React from 'react';
import AccountInput from "../components/form/AccountInput";
import MobileCodeInput from "../components/form/MobileCodeInput";
import LoginButton from "../components/form/LoginButton";
import LoginRedirect from "../components/form/LoginRedirect";
import PasswordInput from "../components/form/PasswordInput";
import NormalInput from "../components/form/NormalInput";
import Utils from "../utils/Utils";
import {Alert, Modal} from "antd";
import AlertPlaceholder from "../components/form/AlertPlaceholder";
import "./RegisterStyle.less";

class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			captcha: {
				instance: null,
				status: "init",
				token: null,
			},
			message: {
				type: "",
				message: ""
			},
			mobile: {
				value: "",
				error: ""
			},
			nickname: {
				value: "",
				error: ""
			},
			code: {
				value: "",
				error: "",
				text: "",
				countdown: -1
			},
			password: {
				value: "",
				error: ""
			},
			repeatPassword: {
				value: "",
				error: ""
			},
			organization: {
				value: "",
				error: ""
			},
			agreement: false,
			loginButton: {
				loading: false
			}
		};
	}

	changeLoginType() {
		this.props.changeLoadComponent("accountLogin");
	}

	componentDidMount() {
		if (this.state.captcha.instance) {
			const that = this;
			const captchaState = this.state.captcha;
			captchaState.instance = this.props.loginConfig.captcha.new({
				groupCode: this.props.loginConfig.group_code,
				vid: this.props.loginConfig.vaptcha_id,
				onSuccess: function (token) {
					that.sendMobileCode(token);
				}
			});
		}
		let loginConfig = Utils.cacheLoginConfig();
		let successUrl = loginConfig.successback;
		console.log(successUrl)
	}

	handleMobileOnBlur(event) {
		const mobileState = this.state.mobile;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			mobileState.error = "请输入手机号码";
			this.setState({
				mobile: mobileState
			});
		} else {
			if (!Utils.isPhoneAvailable(this.state.mobile.value)) {
				this.setState({
					mobile: {
						value: this.state.mobile.value,
						error: "登录手机号码格式错误"
					}
				})
			}
		}
	}

	handleMobileOnChange(event) {
		const loginMobile = this.state.mobile;
		let inputValue = event.target.value;
		loginMobile.value = inputValue;
		if (!inputValue) {
			loginMobile.error = "请输入手机号码";
		} else {
			loginMobile.error = "";
		}
		this.setState({
			mobile: loginMobile
		})
	}

	codeTimeCountDown() {
		let codeState = this.state.code;
		if (codeState.countdown === -1) {
			codeState.countdown = 60;
		} else {
			codeState.countdown--;
		}
		this.setState({
			code: codeState
		});
		if (codeState.countdown !== -1) {
			const that = this;
			setTimeout(function () {
				that.codeTimeCountDown();
			}, 1000);
		}
	}

	handleCodeButtonOnClick() {
		if (!this.state.mobile.value) {
			this.setState({
				mobile: {
					value: this.state.mobile.value,
					error: "请输入手机号码"
				}
			})
			return;
		}
		if (!Utils.isPhoneAvailable(this.state.mobile.value)) {
			this.setState({
				mobile: {
					value: this.state.mobile.value,
					error: "登录手机号码格式错误"
				}
			})
			return;
		}
		if (this.state.captcha.instance) {
			this.state.captcha.instance.verify();
		} else {
			this.sendMobileCode();
		}
	}

	handleCodeOnBlur(event) {
		const loginCode = this.state.code;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginCode.error = "请输入短信验证码";
			this.setState({
				code: loginCode
			});
		}
	}

	handleCodeOnChange(event) {
		const loginCode = this.state.code;
		let inputValue = event.target.value;
		loginCode.value = inputValue;
		if (!inputValue) {
			loginCode.error = "请输入短信验证码";
		} else {
			loginCode.error = "";
		}
		this.setState({
			code: loginCode
		})
	}

	sendMobileCode(token) {
		if (this.state.code.countdown !== -1) {
			Modal.warning({
				content: "操作太频繁，请稍后再试！",
				okText: "确定"
			})
			return;
		}
		const that = this;
		const codeState = this.state.code;
		Utils.sendMobileCodeMessage({
			user_mobile: that.state.mobile.value,
			use_type: "cmc_reg_user",
			captcha_drive: that.props.loginConfig.captcha_drive,
			validate: token
		}, function (result) {
			if (result.data.code === 10000) {
				codeState.text = "短信验证码已发送成功";
				codeState.countdown = -1;
				codeState.error = "";
				that.setState({
					code: codeState
				})
				that.codeTimeCountDown();
			} else {
				if (that.state.captcha.instance) {
					that.state.captcha.instance.reset();
				}
				codeState.error = result.data.message ? result.data.message : "短信验证码发送失败，请稍后重试";
				codeState.countdown = -1;
				that.setState({
					code: codeState
				})
			}
		})
	}

	handlePasswordOnBlur(event) {
		const loginPassword = this.state.password;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginPassword.error = "请设置登录密码";
			this.setState({
				password: loginPassword
			});
		} else {
			if (!Utils.isPasswordAvailable(inputValue, this.props.loginConfig.pwd_len, this.props.loginConfig.pwd_level)) {
				loginPassword.error = "密码不符合当前设定强度";
				this.setState({
					password: loginPassword
				});
			}
		}
	}

	handlePasswordOnChange(event) {
		const loginPassword = this.state.password;
		let inputValue = event.target.value;
		loginPassword.value = inputValue;
		if (!inputValue) {
			loginPassword.error = "请设置登录密码";
		} else {
			loginPassword.error = "";
		}
		this.setState({
			password: loginPassword
		})
	}

	handleRepeatPasswordOnBlur(event) {
		const password = this.state.password;
		const loginPassword = this.state.repeatPassword;
		if (password.value === "") {
			password.error = "请设置登录密码";
			loginPassword.error = "请先设置登录密码";
			this.setState({
				password: password,
				repeatPassword: loginPassword
			});
			return;
		}
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginPassword.error = "请重复登录密码";
			this.setState({
				repeatPassword: loginPassword
			});
		} else {
			if (this.state.password.value !== inputValue) {
				loginPassword.error = "重复密码不匹配";
				this.setState({
					repeatPassword: loginPassword
				});
			}
		}
	}

	handleRepeatPasswordOnChange(event) {
		const loginPassword = this.state.repeatPassword;
		let inputValue = event.target.value;
		loginPassword.value = inputValue;
		if (!inputValue) {
			loginPassword.error = "请重复登录密码";
		} else {
			loginPassword.error = "";
		}
		this.setState({
			repeatPassword: loginPassword
		})
	}

	handleNicknameOnChange(event) {
		const nicknameState = this.state.nickname;
		let inputValue = event.target.value;
		nicknameState.value = inputValue;
		if (!inputValue) {
			nicknameState.error = "请输入账号昵称";
		} else {
			nicknameState.error = "";
		}
		this.setState({
			nickname: nicknameState
		})
	}

	handleNicknameOnBlur(event) {
		const nicknameState = this.state.nickname;
		let inputValue = event.target.value;
		nicknameState.value = inputValue;
		if (!inputValue) {
			nicknameState.error = "请输入账号昵称";
		} else {
			nicknameState.error = "";
		}
		this.setState({
			nickname: nicknameState
		})
	}

	handleOrganizationOnChange(event) {
		const organizationState = this.state.organization;
		let inputValue = event.target.value;
		organizationState.value = inputValue;
		if (!inputValue) {
			organizationState.error = "请输入机构名称";
		} else {
			organizationState.error = "";
		}
		this.setState({
			organization: organizationState
		})
	}

	handleOrganizationOnBlur(event) {
		const organizationState = this.state.organization;
		let inputValue = event.target.value;
		organizationState.value = inputValue;
		if (!inputValue) {
			organizationState.error = "请输入机构名称";
		} else {
			organizationState.error = "";
		}
		this.setState({
			organization: organizationState
		})
	}

	handleAgreementOnChange(event) {
		const agreement = this.state.agreement;
		this.setState({
			agreement: !agreement
		})
	}

	submitForm() {
		console.log(this.state);
		if (!this.state.mobile.value) {
			this.setState({
				mobile: {
					value: this.state.mobile.value,
					error: "请输入手机号码"
				}
			})
			return;
		}
		if (!Utils.isPhoneAvailable(this.state.mobile.value)) {
			this.setState({
				mobile: {
					value: this.state.mobile.value,
					error: "手机号码格式错误"
				}
			})
			return;
		}
		const codeState = this.state.code;
		if (!codeState.value) {
			codeState.error = "请输入短信验证码";
			this.setState({
				code: codeState
			})
			return;
		}
		if (!this.state.password.value) {
			this.setState({
				password: {
					value: this.state.password.value,
					error: "请输入登录密码"
				}
			})
			return;
		}
		if (!Utils.isPasswordAvailable(this.state.password.value)) {
			this.setState({
				password: {
					value: this.state.password.value,
					error: "密码不符合当前设定强度"
				}
			})
			return;
		}
		if (this.state.password.value !== this.state.repeatPassword.value) {
			this.setState({
				repeatPassword: {
					value: this.state.repeatPassword.value,
					error: "重复密码不匹配"
				}
			})
			return;
		}
		if (!this.state.nickname.value) {
			this.setState({
				nickname: {
					value: this.state.nickname.value,
					error: "请输入账号昵称"
				}
			})
			return;
		}
		// if (!this.state.organization.value) {
		// 	this.setState({
		// 		organization: {
		// 			value: this.state.organization.value,
		// 			error: "请输入机构名称"
		// 		}
		// 	})
		// 	return;
		// }
		if (!this.state.agreement) {
			this.setState({
				message: {
					type: "error",
					message: "请勾选 “ 我已阅读并同意《用户协议》”"
				}
			})
			return;
		}
		this.register();
	}

	register() {
		this.setState({
			loginButton: {
				loading: true
			}
		});
		const data = {
			msg_code: this.state.code.value,
			captcha_drive: "false",
			rsa_pwd: Utils.jsEncrypt(this.state.password.value),
			user_mobile: this.state.mobile.value,
			user_nick: this.state.nickname.value,
			group_name: this.state.organization.value,
			is_agree: 1
		}
		Utils.postRegisterForm(data, (result) => {
			console.log(result);
			if (result.data.code === 10000) {
				window.location.href = result.data.data.successback;
			} else {
				if (this.state.captcha.instance) {
					this.state.captcha.instance.reset();
				}
				this.setState({
					loginButton: {
						loading: false
					},
					message: {
						type: "error",
						message: result.data.message ? result.data.message : "注册失败，请稍后重试"
					}
				})
			}
		})
	}

	closeAlert = () => {
		this.setState({
			message: {
				message: ''
			}
		})
	}

	render() {
		let ErrorBox = null;
		if (this.state.message.message) {
			ErrorBox =
				<Alert message={this.state.message.message} description={this.state.message.description} type={this.state.message.type} showIcon banner closable afterClose={this.closeAlert}/>
		} else {
			ErrorBox = <AlertPlaceholder/>
		}
		return (
			<div className="register-box login-form">
				<div className="login-type">
					<button className="active">账号注册</button>
				</div>
				<div className="form-box">
					{ErrorBox}
					<AccountInput
						placeholder="请输入手机号码"
						value={this.state.mobile.value}
						error={this.state.mobile.error}
						onChange={this.handleMobileOnChange.bind(this)}
						onBlur={this.handleMobileOnBlur.bind(this)}/>
					<MobileCodeInput
						value={this.state.code.value}
						error={this.state.code.error}
						message={this.state.code.text}
						countdown={this.state.code.countdown}
						onChange={this.handleCodeOnChange.bind(this)}
						onBlur={this.handleCodeOnBlur.bind(this)}
						sendCode={this.handleCodeButtonOnClick.bind(this)}/>
					<PasswordInput
						type="password"
						name="login-password"
						placeholder="设置登录密码"
						value={this.state.password.value}
						error={this.state.password.error}
						onChange={this.handlePasswordOnChange.bind(this)}
						onBlur={this.handlePasswordOnBlur.bind(this)}
					/>
					<PasswordInput
						type="password"
						name="repeat-password"
						placeholder="重复登录密码"
						value={this.state.repeatPassword.value}
						error={this.state.repeatPassword.error}
						onChange={this.handleRepeatPasswordOnChange.bind(this)}
						onBlur={this.handleRepeatPasswordOnBlur.bind(this)}
					/>
					<NormalInput
						type="text"
						name="login-nick"
						placeholder="请输入账号昵称"
						value={this.state.nickname.value}
						error={this.state.nickname.error}
						onChange={this.handleNicknameOnChange.bind(this)}
						onBlur={this.handleNicknameOnBlur.bind(this)}
					/>
					{/*<NormalInput*/}
					{/*	type="text"*/}
					{/*	name="login-group"*/}
					{/*	placeholder="请输入机构名称"*/}
					{/*	value={this.state.organization.value}*/}
					{/*	error={this.state.organization.error}*/}
					{/*	onChange={this.handleOrganizationOnChange.bind(this)}*/}
					{/*	onBlur={this.handleOrganizationOnBlur.bind(this)}*/}
					{/*/>*/}
					<LoginButton
						name="submit"
						text="立即注册"
						loading={this.state.loginButton.loading}
						submitForm={this.submitForm.bind(this)}/>
					<LoginRedirect
						changeLoadComponent={this.props.changeLoadComponent}
						agreement={{
							onChange: this.handleAgreementOnChange.bind(this),
							checked: this.state.agreement,
							href: this.props.loginConfig.agreement
						}}
						registerToLogin/>
				</div>
			</div>
		);
	}
}

export default Register;