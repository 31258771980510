import React from "react";

class NormalInput extends React.Component {
	render() {
		let message = this.props.message;
		let inputClass = ["login-control"];
		if (this.props.error) {
			inputClass.push("error");
			message = this.props.error;
		}
		return (
			<div className={inputClass.join(" ")}>
				<input
					className="input-field"
					autoComplete={this.props.autoComplete}
					type={this.props.type ? this.props.type : "text"}
					name={this.props.name}
					value={this.props.value}
					placeholder={this.props.placeholder}
					onChange={this.props.onChange}
					onBlur={this.props.onBlur}/>
				<div className="notice-message">{message}</div>
			</div>
		);
	}
}

export default NormalInput;