import React from 'react';
import {Button} from "antd";

class LoginButton extends React.Component {
	render() {
		return (
			<Button htmlType={this.props.htmlType?this.props.htmlType:'button'} name={this.props.name} className="login-button" type={this.props.type?this.props.type:'primary'} loading={this.props.loading} onClick={this.props.submitForm}>
				{this.props.text}
			</Button>
		);
	}
}

export default LoginButton;