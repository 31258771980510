import React from 'react';
import Utils from "./utils/Utils";
import {Layout, Alert, Modal} from 'antd';
import Loading from './components/loading/Loading'
import Brand from "./components/brand/Brand";
import Footbar from "./components/footbar/Footbar";
import Geetest from "./utils/Geetest";
import Vaptcha from "./utils/Vaptcha";
import Helmet from "react-helmet";
import AccountLogin from "./pages/AccountLogin";
import MobileLogin from "./pages/MobileLogin";
import RetrievePassword from "./pages/RetrievePassword";
import Register from "./pages/Register";
import './App.less';

const {Header, Footer, Content} = Layout;

class App extends React.Component {
	constructor(props) {
		super(props);
		let state = {
			error: null,
			component: "",
			loginConfig: null,
			queryParams: {}
		};

		if (Utils.checkPathName("wb-login")) {
			state.error = "微博登录未实现";
		}
		this.state = state;
	}

	componentDidMount() {
		this.loadLoginConfig();
	}

	loadLoginConfig() {
		let loginConfig = {
			back_img: "/loginv2/login/images/2k/bg.png",
			captcha_drive: "false",
			failback: "https://login.dev.chinamcloud.cn",
			favicon_url: "",
			footer: "",
			group_id: "",
			header_logo: "logo.png",
			header_name: "融合媒体平台",
			index_url: "https://www.chinamcloud.com",
			is_agent_group: true,
			login_bg: "pic.png",
			login_index_name: "登录云服务",
			login_level: "1",
			open_register: "1",
			pwd_len: "6",
			pwd_level: "1",
			successback: "https://console.dev.chinamcloud.cn",
			theme_type: "blue",
			unique_login: "0",
			reset_password: "1",
			login_from: "local",
			group_code: Utils.getGroupCode(),
			vaptcha_id: "5ceb53f0fc650e3314195c6b"
		};
		if (Utils.checkPathName("auth-login")) {
			loginConfig['login_from'] = "oauth-login";
			loginConfig['service_key'] = Utils.getQueryString("service_key");
			loginConfig['successback'] = Utils.getQueryString("successback");
			loginConfig['group_code'] = Utils.getQueryString("group_code");
		}
		const that = this;
		Utils.getLoginConfig(loginConfig.group_code, function (result) {
			if (result.data.code === 10000) {
				loginConfig = Object.assign({}, loginConfig, result.data.data);
				let successUrl = Utils.getQueryString("s_url");
				console.log(successUrl);
				if (successUrl) {
					if (successUrl[0] === '/') {
						let lastChar = loginConfig.successback.slice(-1);
						if (lastChar === '/') {
							successUrl = loginConfig.successback.slice(0, -1) + successUrl;
						} else {
							successUrl = loginConfig.successback + successUrl;
						}
					}
					loginConfig.successback = successUrl;
				}
				Utils.cacheLoginConfig(loginConfig);
				switch (loginConfig.captcha_drive) {
					case "GEETEST":
						Geetest.load(function () {
							loginConfig.captcha = Geetest;
							that.setState({
								loginConfig: loginConfig
							});
						});
						break;
					case "VAPTCHA":
						Vaptcha.load(function () {
							loginConfig.captcha = Vaptcha;
							that.setState({
								loginConfig: loginConfig
							});
						});
						break;
					default:
						that.setState({
							loginConfig: loginConfig
						});
				}

			} else {
				Modal.error({
					content: result.data.message ? result.data.message : "获取登录配置失败，请重试！",
					okText: "重试",
					onOk() {
						that.loadLoginConfig();
					}
				})
			}
		});
	}

	changeLoadComponent(component) {
		this.setState({
			"component": component
		})
	}

	render() {
		if (!this.state.loginConfig) {
			return (
				<Layout className="container-box">
					<Loading></Loading>
				</Layout>
			);
		}
		let DisplayBox = null;
		switch (this.state.component) {
			case "register":
				DisplayBox = <Register loginConfig={this.state.loginConfig} changeLoadComponent={this.changeLoadComponent.bind(this)}/>;
				break;
			case "retrievePassword":
				DisplayBox = <RetrievePassword loginConfig={this.state.loginConfig} changeLoadComponent={this.changeLoadComponent.bind(this)}/>;
				break;
			case "mobileLogin":
				DisplayBox = <MobileLogin loginConfig={this.state.loginConfig} changeLoadComponent={this.changeLoadComponent.bind(this)}/>;
				break;
			default:
				DisplayBox = <AccountLogin loginConfig={this.state.loginConfig} changeLoadComponent={this.changeLoadComponent.bind(this)}/>;
		}
		let ErrorBox = null;
		if (this.state.error) {
			ErrorBox = <Alert message="获取登录配置失败"
							  description={this.state.error}
							  type="error" showIcon/>
		}
		let Foot = null;
		if (this.state.loginConfig.footer) {
			Foot = <Footer className="container-footer">
				<Footbar footer={this.state.loginConfig.footer}></Footbar>
			</Footer>
		}
		return (
			<Layout className="container-box">
				<Header className="container-header">
					<Helmet title={this.state.loginConfig.header_name} link={[{rel:"shortcut icon",href:this.state.loginConfig.favicon_url}]}>
					</Helmet>
					<Brand href={this.state.loginConfig.index_url} src={this.state.loginConfig.header_logo}
						   alt={this.state.loginConfig.header_name}></Brand>
				</Header>
				<Content className="container-content">
					{ErrorBox}
					<div className="login-box">
						<div className="login-container">
							<div className="login-img">
								<img src={process.env.PUBLIC_URL + '/pic.png'} alt=""/>
							</div>
							{DisplayBox}
						</div>
					</div>
				</Content>
				{Foot}
			</Layout>
		);
	}
}

export default App;