import React from "react";
import {Button} from "antd";

class MobileCodeInput extends React.Component {
	render() {
		let message = this.props.message;
		let inputClass = ["login-control", "login-code"];
		if (this.props.error) {
			inputClass.push("error");
			message = this.props.error;
		}
		let buttonText = "获取验证码";
		let buttonClass = ["code-button"];
		if (this.props.countdown >= 0) {
			buttonClass.push("disabled");
			buttonText = "重新发送(" + this.props.countdown + "s)";
		}
		return (
			<div className={inputClass.join(" ")}>
				<input
					className="input-field"
					type="text"
					name="login-code"
					autoComplete={this.props.autoComplete}
					placeholder="请输入短信验证码"
					value={this.props.value}
					onBlur={this.props.onBlur}
					onChange={this.props.onChange}/>
				<Button
					className={buttonClass.join(" ")}
					type="link"
					loading={this.props.loading}
					disabled={this.props.countdown > 0 ? true : false}
					onClick={this.props.sendCode}>{buttonText}</Button>
				<div
					className="notice-message">{message}</div>
			</div>
		);
	}
}

export default MobileCodeInput;