import React from "react";
import "./AlertPlaceholderStyle.less";

class AlertPlaceholder extends React.Component {
	render() {
		return (
			<div className="alert-placeholder">

			</div>
		);
	}
}

export default AlertPlaceholder;