import React from 'react';

class LoginDuration extends React.Component {
	render() {
		return (
			<div className="login-duration">
				<input id="login-duration" type="checkbox" name="login-duration" checked={this.props.checked}
					   onChange={this.props.onChange}/>
				<label htmlFor="login-duration">3天内自动登录</label>
			</div>
		);
	}
}

export default LoginDuration;