import React from "react";
import AccountInput from "../components/form/AccountInput";
import MobileCodeInput from "../components/form/MobileCodeInput";
import LoginDuration from "../components/form/LoginDuration";
import LoginButton from "../components/form/LoginButton";
import LoginRedirect from "../components/form/LoginRedirect";
import Utils from "../utils/Utils";
import {Alert, Modal} from "antd";
import ForceLogin from "./ForceLogin";
import "./MobileLoginStyle.less";
import AlertPlaceholder from "../components/form/AlertPlaceholder";

class MobileLogin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			captcha: {
				instance: null,
				status: "init",
				token: null,
			},
			message: {
				type: "",
				message: ""
			},
			account: {
				value: "",
				error: ""
			},
			code: {
				value: "",
				error: "",
				text: "",
				countdown: -1
			},
			loginDuration: false,
			loginButton: {
				loading: false
			},
			modal: {
				name: "",
				mobile: "",
				token: ""
			}
		};
	}

	componentDidMount() {
		if (this.props.loginConfig.captcha) {
			const that = this;
			const captchaState = this.state.captcha;
			captchaState.instance = this.props.loginConfig.captcha.new({
				groupCode: this.props.loginConfig.group_code,
				vid: this.props.loginConfig.vaptcha_id,
				onSuccess: function (token) {
					that.sendMobileCode(token);
				}
			});
			this.setState({
				captcha: captchaState
			})
		}
	}

	changeLoginType(event, type) {
		this.props.changeLoadComponent(type ? type : "accountLogin");
	}

	handleAccountOnBlur(event) {
		const loginAccount = this.state.account;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginAccount.error = "请输入手机号码";
			this.setState({
				account: loginAccount
			});
		} else {
			if (!Utils.isPhoneAvailable(this.state.account.value)) {
				this.setState({
					account: {
						value: this.state.account.value,
						error: "登录手机号码格式错误"
					}
				})
			}
		}
	}

	handleAccountOnChange(event) {
		const loginAccount = this.state.account;
		let inputValue = event.target.value;
		loginAccount.value = inputValue;
		if (!inputValue) {
			loginAccount.error = "请输入手机号码";
		} else {
			loginAccount.error = "";
		}
		this.setState({
			account: loginAccount
		})
	}

	codeTimeCountDown() {
		let codeState = this.state.code;
		if (codeState.countdown === -1) {
			codeState.countdown = 60;
		} else {
			codeState.countdown--;
		}
		this.setState({
			code: codeState
		});
		if (codeState.countdown !== -1) {
			const that = this;
			setTimeout(function () {
				that.codeTimeCountDown();
			}, 1000);
		}
	}

	handleCodeButtonOnClick() {
		if (this.state.code.countdown !== -1) {
			Modal.warning({
				content: "操作太频繁，请稍后再试！",
				okText: "确定"
			})
			return;
		}
		if (!this.state.account.value) {
			this.setState({
				account: {
					value: this.state.account.value,
					error: "请输入手机号码"
				}
			})
			return;
		}
		if (!Utils.isPhoneAvailable(this.state.account.value)) {
			this.setState({
				account: {
					value: this.state.account.value,
					error: "登录手机号码格式错误"
				}
			})
			return;
		}
		if (this.state.captcha.instance) {
			this.state.captcha.instance.verify();
		} else {
			this.sendMobileCode();
		}
	}

	handleCodeOnBlur(event) {
		const loginCode = this.state.code;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			loginCode.error = "请输入短信验证码";
			this.setState({
				code: loginCode
			});
		}
	}

	handleCodeOnChange(event) {
		const loginCode = this.state.code;
		let inputValue = event.target.value;
		loginCode.value = inputValue;
		if (!inputValue) {
			loginCode.error = "请输入短信验证码";
		} else {
			loginCode.error = "";
		}
		this.setState({
			code: loginCode
		})
	}

	handleDurationOnChange(event) {
		const loginDuration = this.state.loginDuration;
		this.setState({
			loginDuration: loginDuration ? false : true
		})
	}

	sendMobileCode(token) {
		const that = this;
		const codeState = this.state.code;
		codeState.loading = true;
		this.setState({
			code: codeState
		});
		Utils.sendMobileCodeMessage({
			user_mobile: that.state.account.value,
			group_code: that.props.loginConfig.group_code,
			use_type: "cmc_mob_login",
			captcha_drive: that.props.loginConfig.captcha_drive,
			validate: token
		}, function (result) {
			console.log(result);
			codeState.loading = false;
			if (result.data.code === 10000) {
				codeState.text = "短信验证码已发送成功";
				codeState.countdown = -1;
				codeState.error = "";
				that.setState({
					code: codeState
				})
				that.codeTimeCountDown();
			} else {
				codeState.error = result.data.message ? result.data.message : "短信验证码发送失败，请稍后重试";
				codeState.countdown = -1;
				if (that.state.captcha.instance) {
					that.state.captcha.instance.reset();
				}
				that.setState({
					code: codeState
				})
			}
		})
	}

	submitForm(event) {
		event.stopPropagation();
		event.preventDefault();
		if (!this.state.account.value) {
			this.setState({
				account: {
					value: this.state.account.value,
					error: "请输入手机号码"
				}
			})
			return;
		}
		if (!Utils.isPhoneAvailable(this.state.account.value)) {
			this.setState({
				account: {
					value: this.state.account.value,
					error: "登录手机号码格式错误"
				}
			})
			return;
		}
		if (!this.state.code.value) {
			this.setState({
				code: {
					value: this.state.code.value,
					error: "请输入短信验证码",
					countdown: this.state.code.countdown
				}
			})
			return;
		}
		this.login();
	}

	login() {
		this.setState({
			loginButton: {
				loading: true
			}
		});
		const loginData = {
			user_mobile: this.state.account.value,
			group_code: this.props.loginConfig.group_code,
			msg_pwd: this.state.code.value,
			login_type: "MUCode",
			login_mode: 1
		};
		if (this.state.loginDuration) {
			loginData["login_timeout"] = window.configs.login_duration;
		}
		loginData["captcha_drive"] = "false";
		loginData["validate"] = "";
		let loginUrl = window.configs.local_login_url;
		if (this.props.loginConfig.login_from === "oauth-login") {
			loginData["service_key"] = this.props.loginConfig.service_key;
			loginData["successback"] = this.props.loginConfig.successback;
			loginUrl = window.configs.oauth_login_url;
		}
		const that = this;
		Utils.postLoginForm(loginData, loginUrl, function (result) {
			console.log(result);
			switch (result.data.code) {
				case 10000:
				case 20000:
					window.location.href = result.data.data.successback;
					;
					break;
				case 99508:
					if (that.state.captcha.instance) {
						that.state.captcha.instance.reset();
					}
					that.setState({
						loginButton: {
							loading: false
						},
						message: {
							type: "error",
							message: result.data.message ? result.data.message : "登录失败，请稍后重试"
						}
					});
					break;
				case 99815:
					//需要修改密码
					that.changeLoginType("ResetPassword");
					break;
				default:
					if (that.state.captcha.instance) {
						that.state.captcha.instance.reset();
					}
					that.setState({
						loginButton: {
							loading: false
						},
						message: {
							type: "error",
							message: result.data.message ? result.data.message : "登录失败，请稍后重试"
						}
					});
			}
		});
	}

	modalHandleOk() {
	}

	modalHandleCancel() {
		this.setState({
			loginButton: {
				loading: false
			},
			modal: {
				name: "",
				mobile: "",
				token: ""
			}
		});
	}

	closeAlert = () => {
		this.setState({
			message: {
				message: ''
			}
		})
	}

	render() {
		let ErrorBox = null;
		if (this.state.message.message) {
			ErrorBox = <Alert message={this.state.message.message} description={this.state.message.description} type={this.state.message.type} showIcon banner closable afterClose={this.closeAlert}/>
		} else {
			ErrorBox = <AlertPlaceholder/>
		}
		let modalAlert = "";
		if (this.state.modal.name === "ForceLogin") {
			modalAlert = <ForceLogin {...this.state.modal} onOk={this.modalHandleOk.bind(this)} onCancel={this.modalHandleCancel.bind(this)}></ForceLogin>
		}
		return (
			<div className="login-form mobile-login-form">
				<div className="top-type"><img src={process.env.PUBLIC_URL + "/qrcode.png"} alt="qrcode"/></div>
				<div className="login-type">
					<button onClick={this.changeLoginType.bind(this, "accountPassword")}>密码登录</button>
					<button className="active">验证码登录</button>
				</div>
				<div className="mobile-code-login-box">
					{ErrorBox}
					{modalAlert}
					<form onSubmit={this.submitForm.bind(this)}>
						<AccountInput placeholder="请输入手机号码" value={this.state.account.value}
									  error={this.state.account.error}
									  onChange={this.handleAccountOnChange.bind(this)}
									  onBlur={this.handleAccountOnBlur.bind(this)}></AccountInput>
						<MobileCodeInput {...this.state.code}
										 onChange={this.handleCodeOnChange.bind(this)}
										 onBlur={this.handleCodeOnBlur.bind(this)}
										 sendCode={this.handleCodeButtonOnClick.bind(this)}></MobileCodeInput>
						<LoginDuration onChange={this.handleDurationOnChange.bind(this)}
									   checked={this.state.loginDuration}></LoginDuration>
						<LoginButton htmlType="submit" name="submit" text="登录"
									 loading={this.state.loginButton.loading}
						></LoginButton>
						<LoginRedirect resetPassword={this.props.loginConfig.reset_password === "1"}
									   register={this.props.loginConfig.open_register === "1"}
									   changeLoadComponent={this.props.changeLoadComponent}></LoginRedirect>
					</form>
				</div>
			</div>
		);
	}
}

export default MobileLogin;