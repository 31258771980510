import React from "react";

class AccountInput extends React.Component {
	render() {
		let inputClass = ["login-control"];
		if (this.props.error !== "") {
			inputClass.push("error");
		}
		return (
			<div
				className={inputClass.join(" ")}>
				<input
					className="input-field"
					type="text"
					name="login-account"
					value={this.props.value}
					autoComplete={this.props.autoComplete}
					placeholder={this.props.placeholder}
					onChange={this.props.onChange}
					onBlur={this.props.onBlur}/>
				<div
					className="notice-message">{this.props.error}</div>
			</div>
		);
	}
}

export default AccountInput;