import React from 'react';
import './Footbar.less'

class Footbar extends React.Component {
	render() {
		return (
			<div className="footer-box" dangerouslySetInnerHTML={{__html: this.props.footer}}></div>
		);
	}
}

export default Footbar;