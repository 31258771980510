import React from "react";
import {Modal} from "antd";
import MobileCodeInput from "../components/form/MobileCodeInput";
import Utils from "../utils/Utils";
import AccountInput from "../components/form/AccountInput";

class SetMobile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mobile: {
				value: "",
				text: "",
				error: ""
			},
			code: {
				value: "",
				text: "",
				error: "",
				countdown: -1
			},
			loading: false
		};
	}

	sendMobileCode() {
		if (!this.state.mobile.value) {
			this.setState({
				mobile: {
					value: this.state.mobile.value,
					error: "请输入手机号码"
				}
			})
			return;
		}
		if (!Utils.isPhoneAvailable(this.state.mobile.value)) {
			this.setState({
				mobile: {
					value: this.state.mobile.value,
					error: "登录手机号码格式错误"
				}
			})
			return;
		}
		const that = this;
		let codeState = that.state.code;
		codeState.loading = true;
		this.setState({
			code: codeState
		})
		Utils.sendMobileCodeMessage({
			user_mobile: this.state.mobile.value,
			use_type: "cmc_reset_mob",
			captcha_drive: "false"
		}, function (result) {
			console.log(result);
			codeState.loading = false;
			if (result.data.code === 10000) {
				codeState.countdown = -1;
				codeState.error = "";
				codeState.text = "短信验证码已发送成功"
				that.setState({
					code: codeState
				})
				that.codeTimeCountDown();
			} else {
				codeState.countdown = -1;
				codeState.error = result.data.message ? result.data.message : "短信验证码发送失败，请稍后重试"
				that.setState({
					code: codeState
				})
			}
		})
	}

	codeTimeCountDown() {
		let codeState = this.state.code;
		if (codeState.countdown === -1) {
			codeState.countdown = 60;
		} else {
			codeState.countdown--;
		}
		this.setState({
			code: codeState
		});
		if (codeState.countdown !== -1) {
			const that = this;
			setTimeout(function () {
				that.codeTimeCountDown();
			}, 1000);
		}
	}

	handleMobileOnBlur(event) {
		const mobileState = this.state.mobile;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			mobileState.error = "请输入手机号码";
			this.setState({
				mobile: mobileState
			});
		} else {
			if (!Utils.isPhoneAvailable(this.state.mobile.value)) {
				this.setState({
					mobile: {
						value: this.state.mobile.value,
						error: "登录手机号码格式错误"
					}
				})
			}
		}
	}

	handleMobileOnChange(event) {
		const mobileState = this.state.mobile;
		let inputValue = event.target.value;
		mobileState.value = inputValue;
		if (!inputValue) {
			mobileState.error = "请输入手机号码";
		} else {
			mobileState.error = "";
		}
		this.setState({
			mobile: mobileState
		})
	}

	handleModalButtonClick(type, event) {
		if (type === "ok") {
			if (!this.state.mobile.value) {
				this.setState({
					mobile: {
						value: this.state.mobile.value,
						error: "请输入手机号码"
					}
				})
				return;
			}
			if (!Utils.isPhoneAvailable(this.state.mobile.value)) {
				this.setState({
					mobile: {
						value: this.state.mobile.value,
						error: "登录手机号码格式错误"
					}
				})
				return;
			}
			if (this.state.code.value === "") {
				this.setState({
					code: {
						error: "请输入短信验证码"
					}
				})
				return;
			}
			this.setState({
				loading: true
			});
			const that = this;
			Utils.postLoginForm({
				"captcha_drive": false,
				"user_token": this.props.token,
				"user_mobile": this.state.mobile.value,
				"msg_pwd": this.state.code.value,
				'login_type': "UTRM"
			}, this.props.loginUrl, function (result) {
				switch (result.data.code) {
					case 10000:
					case 20000:
						window.location.href = result.data.data.successback;
						break;
					case 99500:
						Modal.error({
							content: result.data.message ? result.data.message : "设置手机号码失败，请重试！",
							okText: "确定",
							onOk() {
								that.setState({
									loading: false,
									code: {
										value: "",
										countdown: that.state.code.countdown,
										message: "",
										error: ""
									}
								});
							}
						})
						break;
					default:
						Modal.error({
							content: result.data.message ? result.data.message : "设置手机号码失败，请重试！",
							okText: "确定",
							onOk() {
								that.setState({
									loading: false,
									code: {
										value: "",
										countdown: that.state.code.countdown,
										message: "",
										error: ""
									}
								});
							}
						})
				}
			});
		} else {
			this.props.onCancel();
		}
	}

	handleCodeButtonOnClick() {
		if (this.state.code.countdown !== -1) {
			Modal.warning({
				content: "操作太频繁，请稍后再试！",
				okText: "确定"
			})
			return;
		}
		this.sendMobileCode();
	}

	handleCodeOnChange(event) {
		let codeState = this.state.code;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		let error = "";
		if (!inputValue) {
			error = "请输入短信验证码";
		}
		codeState.error = error;
		codeState.value = inputValue;
		this.setState({
			code: codeState
		})
	}

	handleCodeOnBlur(event) {
		let codeState = this.state.code;
		let inputValue = event.target.value ? event.target.value.trim() : "";
		if (inputValue === "") {
			codeState.value = inputValue;
			codeState.error = "请输入短信验证码";
			this.setState({
				code: codeState
			});
		}
	}

	render() {
		return (
			<Modal wrapClassName="set-mobile"
				   title="完善信息"
				   visible
				   onOk={this.handleModalButtonClick.bind(this, "ok")}
				   okText="保存"
				   confirmLoading={this.state.loading}
				   onCancel={this.handleModalButtonClick.bind(this, "cancel")}
				   cancelText="取消"
			>
				<AccountInput placeholder="请输入手机号码" value={this.state.mobile.value}
							  error={this.state.mobile.error}
							  onChange={this.handleMobileOnChange.bind(this)}
							  onBlur={this.handleMobileOnBlur.bind(this)}></AccountInput>
				<MobileCodeInput {...this.state.code}
								 onChange={this.handleCodeOnChange.bind(this)}
								 onBlur={this.handleCodeOnBlur.bind(this)}
								 sendCode={this.handleCodeButtonOnClick.bind(this)}/>
			</Modal>);
	}
}

export default SetMobile;