import React from 'react';

class LoginRedirect extends React.Component {
	submitForm() {
		this.props.submitForm();
	}

	gotoResetPassword() {
		this.props.changeLoadComponent("retrievePassword");
	}

	gotoRegister() {
		this.props.changeLoadComponent("register");
	}

	gotoLogin() {
		this.props.changeLoadComponent("login");
	}

	render() {
		let buttons = [];
		if (this.props.agreement) {
			buttons.push(<span key="login-agreement"><input type="checkbox" id="login-agreement" name="login-agreement"
															value="1" checked={this.props.agreement.checked}
															onChange={this.props.agreement.onChange}/><label
				htmlFor="login-agreement">我已阅读并同意<a href={process.env.PUBLIC_URL + "/agreement.html"} className="agreement"
													target="_blank" rel="noopener noreferrer">《用户协议》</a></label></span>)
		}
		if (this.props.resetPassword) {
			buttons.push(<button key="reset-password" onClick={this.gotoResetPassword.bind(this)}
								 className="reset-password">忘记密码</button>)
		}
		if (this.props.register) {
			buttons.push(<button key="register" onClick={this.gotoRegister.bind(this)}
								 className="register">快速注册</button>)
		}
		if (this.props.registerToLogin) {
			buttons.push(<span key="login">已有账号，<button onClick={this.gotoLogin.bind(this)}
														className="register-to-login">立即登录</button></span>)
		}
		if (this.props.resetToLogin) {
			buttons.push(<span key="login"><button onClick={this.gotoLogin.bind(this)}
												   className="login">立即登录</button></span>)
		}
		return (
			<div className="login-redirect">
				{buttons}
			</div>
		);
	}
}

export default LoginRedirect;